import * as React from "react";
import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import { cva } from "class-variance-authority";
import { ChevronDown } from "lucide-react";

import { cn } from "@/lib/utils";
import { navTriggerStyle } from "../other/constants";

const NavigationMenu = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root
    ref={ref}
    className={cn("relative z-10 flex max-w-full w-full justify-between", className)}
    {...props}
  >
    {children}
    <NavigationMenuViewport />
  </NavigationMenuPrimitive.Root>
));
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List
    ref={ref}
    className={cn("group flex flex-1 list-none items-center justify-center space-x-1", className)}
    {...props}
  />
));
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;

const NavigationMenuItem = NavigationMenuPrimitive.Item;

const navigationMenuTriggerStyle = cva(
  `group inline-flex w-max items-center text-slate justify-center rounded-[6px] p-x-2 text-[.875rem] leading-[1.5rem] 
  font-normal transition-all outline-none focus-visible:outline-dijon 
  disabled:pointer-events-none active:outline-none relative disabled:opacity-50  
  after:absolute after:h-[1px] after:bottom-0 after:left-0 after:right-0 after:origin-top-left after:bg-slate 
  after:transition after:scale-x-0 hover:after:scale-x-100 
  data-[state=open]:outline-none data-[state=open]:after:scale-x-100 data-[state=open]:font-normal`
);

const NavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger> & { showChevron?: boolean }
>(({ className, children, showChevron = true, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    ref={ref}
    className={cn(navTriggerStyle, "group h-8", className)}
    // className={cn(navigationMenuTriggerStyle(), "group", className)}
    {...props}
  >
    {children}
    {showChevron && (
      <ChevronDown
        className="relative  ml-1 h-4 w-4 transition duration-200 group-data-[state=open]:rotate-180"
        aria-hidden="true"
      />
    )}
  </NavigationMenuPrimitive.Trigger>
));
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;

const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, ...props }, ref) => {
  const [winInnerHeight, setWinInnerHeight] = React.useState(200);

  React.useEffect(() => {
    setWinInnerHeight(window.innerHeight);

    window.addEventListener("resize", winResize, false);

    return () => window.removeEventListener("resize", winResize);
  }, []);

  const winResize = (e: Event) => {
    const target = e.target as Window;

    setWinInnerHeight(target.innerHeight);
  };

  return (
    <NavigationMenuPrimitive.Content
      ref={ref}
      style={{ maxHeight: `${winInnerHeight - 150}px` }}
      className={cn(
        `left-0 top-0 w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in 
        data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 
        data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 
        data-[motion=to-start]:slide-out-to-left-52 md:absolute overflow-y-auto transition-all`,
        className
      )}
      {...props}
    />
  );
});
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;

const NavigationMenuLink = NavigationMenuPrimitive.Link;

const NavigationMenuViewport = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
>(({ className, ...props }, ref) => (
  <div className={cn("absolute left-0 top-full flex justify-center right-0")}>
    <NavigationMenuPrimitive.Viewport
      className={cn(
        `origin-top-center relative mt-[13px] h-[var(--radix-navigation-menu-viewport-height)] w-full 
        overflow-hidden bg-white text-slate-950 shadow-xl border border-t-0 border-slate-10
        data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 
        data-[state=open]:zoom-in-90 md:w-[100%]`,
        className
      )}
      ref={ref}
      {...props}
    />
  </div>
));
NavigationMenuViewport.displayName = NavigationMenuPrimitive.Viewport.displayName;

const NavigationMenuIndicator = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Indicator
    ref={ref}
    className={cn(
      "top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in",
      className
    )}
    {...props}
  >
    <div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-slate-200 shadow-md" />
  </NavigationMenuPrimitive.Indicator>
));
NavigationMenuIndicator.displayName = NavigationMenuPrimitive.Indicator.displayName;

export {
  navigationMenuTriggerStyle,
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuContent,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuIndicator,
  NavigationMenuViewport,
};
