import { cn } from "@/lib/utils";
import Image from "next/image";

interface IProfileButtonProps {
  className?: string;
  linkToPlatform?: string;
  avatarIconUrl: string;
  email?: string | null;
  portalLoginCtaLink: string;
  label: string;
}

const ProfileButton: React.FC<IProfileButtonProps> = ({
  className,
  linkToPlatform,
  avatarIconUrl,
  email,
  portalLoginCtaLink,
  label,
}) => {
  return (
    <button
      aria-label="profile button"
      className={cn(
        `flex outline-none pr-3 pl-2 rounded-[6px] h-12  focus-visible:outline-dijon bg-white w-fit 
        justify-center items-center gap-2 hover:bg-black-5 transition`,
        className
      )}
      onClick={() => {
        const finalLink = portalLoginCtaLink || linkToPlatform;
        finalLink && (document.location = finalLink);
      }}
    >
      <div className="flex items-center justify-center rounded-full">
        <Image
          src={avatarIconUrl}
          width="48"
          height="48"
          alt={email ?? "Default user profile icon"}
          className="object-cover w-10 h-10 rounded-full"
        />
      </div>

      {label && <span className="text-royal-blue text-14 font-[500] font-sairaSemiCondensed">{label}</span>}
    </button>
  );
};

export default ProfileButton;
