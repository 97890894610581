"use client";

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { X } from "lucide-react";

import { colorsMap, fallbackColors } from "../other/constants";
import { cn } from "@/lib/utils";
import RichTextRenderer from "../other/RichTextRenderer";

interface WebsiteBanner {
  websiteBanner?: any;
}

const WebsiteBanner: React.FC<WebsiteBanner> = ({ websiteBanner }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { textColor, bgColorClass } = colorsMap[websiteBanner.background] ?? fallbackColors;

  useEffect(() => {
    console.log(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (!websiteBanner.id) return;
    const storedBanner: any = JSON.parse(localStorage.getItem("bannerData") ?? "{}");

    if (storedBanner[websiteBanner.id]) return;

    setIsVisible(true);

    const temObj = {
      ...storedBanner,
      [websiteBanner.id]: "1",
    };

    localStorage.setItem("bannerData", JSON.stringify(temObj));
  }, [websiteBanner.id]);

  function close() {
    setIsVisible(false);
  }

  if (!isVisible) {
    return null;
  }

  if (websiteBanner.type === "top") {
    return (
      <div className={cn("fixed top-0 w-full z-[500] border-b-[1px] border-black-40 max-sm:pr-8", bgColorClass)}>
        <RichTextRenderer
          paddingClass="py-0"
          className={cn("pt-4", textColor)}
          richTextDocument={websiteBanner.bannerDescription}
          closeModal={close}
        />
        <CloseButton close={close} />
      </div>
    );
  } else {
    return (
      <Popup
        isVisible={isVisible}
        textColor={textColor}
        content={websiteBanner.bannerDescription}
        bgColorClass={bgColorClass}
        close={close}
      />
    );
  }
};

export default WebsiteBanner;

const Popup = ({
  isVisible,
  textColor,
  content,
  bgColorClass,
  close,
}: {
  isVisible: boolean;
  textColor: string;
  content: any;
  bgColorClass: string;
  close: () => void;
}) => {
  return (
    <>
      <Dialog open={isVisible} as="div" className="relative z-[300] focus:outline-none" onClose={close}>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4">
            <DialogPanel
              transition
              className={cn(
                `w-full max-w-md p-6 
              border border-black-40 duration-300 ease-out data-[closed]:transform-[scale(95%)]
                data-[closed]:opacity-0 relative`,
                bgColorClass
              )}
            >
              <CloseButton close={close} />
              <RichTextRenderer
                paddingClass="py-0"
                className={cn("pt-4", textColor)}
                richTextDocument={content}
                closeModal={close}
              />
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const CloseButton = ({ close }: { close: () => void }) => (
  <button
    onClick={close}
    className="top-4 right-4 hover:text-black-60 text-black-40 absolute p-1 bg-gray-300 rounded-full"
  >
    <X />
  </button>
);
