/* eslint-disable @typescript-eslint/no-explicit-any */
import CustomLink from "../other/CustomLink";
import Image from "next/image";
import { AppIconButton } from "../buttons/AppIconButton";
import { ChevronRight } from "lucide-react";
import AppText from "../other/AppText";
import { cn } from "@/lib/utils";
import AppButton from "../buttons/AppButton";
// import { BODY_SMALL_EMPHASIS } from "@/styles";

const NavPanelNoMenu = ({ data, closeMenu }: { data: any; closeMenu: () => void }) => {
  return (
    <div className="flex">
      <div className={`p-6 ${data.featuredCard || data.secondaryFeaturedCard ? "pt-4" : "pt-8"}`}>
        <div className="flex items-center justify-between">
          <div>
            <AppText type={"SUB_HEADING_SMALL"} className="text-royal-blue">
              {data.tabHeading}
            </AppText>

            <AppText type={"BODY_SMALL"} className={"text-black-80"}>
              {data.tabSubheading}
            </AppText>
          </div>

          {data.tabCtaLink && (
            <AppIconButton
              icon={<ChevronRight size={20} aria-label="chevron right icon" />}
              label={data.tabCtaLabel}
              intent={"ghost"}
              iconOnRightSide={true}
              link={data.tabCtaLink}
              className="py-1"
            />
          )}
        </div>

        <div
          className={cn(
            "grid gap-x-8 gap-y-8 w-full xl:gap-x-8",
            data.cards.length > 5 ? "grid-rows-2" : "grid-rows-1",
            data.tabHeading || data.tabSubheading || data.tabCtaLink || data.tabCtaLabel ? "mt-8" : "mt-0",
            data.featuredCard || data.secondaryFeaturedCard ? "grid-cols-12" : "grid-cols-10"
          )}
        >
          {(data.cards || []).map((card: any) => {
            return (
              <CustomLink
                href={card.ctaLink}
                key={card.id}
                className={cn(
                  "group flex flex-col overflow-hidden",
                  data.featuredCard || data.secondaryFeaturedCard ? "xl:col-span-4 col-span-6" : "col-span-2"
                )}
              >
                <div className="overflow-hidden relative aspect-[16/9]">
                  <Image
                    className="group-hover:scale-105 aspect-[16/9] object-cover w-full transition"
                    fill
                    placeholder="blur"
                    blurDataURL={card.placeholder}
                    sizes="100vw"
                    src={card.image}
                    alt={card.ctaLabel}
                    priority
                  />
                </div>
                <div className="font-saira mt-2 mb-2 text-[#000000] font-[600] text-[14px]">{card.ctaLabel}</div>
                {card.subTitle ? (
                  <div className="font-saira mb-1 font-[400] text-[#333333] text-[12px] break-words overflow-hidden line-clamp-4">
                    {card.subTitle}
                  </div>
                ) : (
                  <a href={card.ctaLink} className="font-saira mb-1 font-[400] text-[#000000] text-[12px] break-all">
                    {card.linkHeading}
                  </a>
                )}

                {/* {kind === "About us" && <AppText type="BODY_SMALL">{card.subTitle}</AppText>} */}
              </CustomLink>
            );
          })}
        </div>
      </div>
      {(data.featuredCard || data.secondaryFeaturedCard) && (
        <div className="flex flex-col gap-3 py-4 mr-4">
          {/* Primary Feature Card Block */}
          {data.featuredCard && (
            <div className="group overflow-hidden bg-white cursor-pointer w-[428px]">
              <h2 className="mb-2 text-[24px] font-sairaSemiCondensed font-[600] break-words text-[#000000]">
                {data.featuredCard.label}
              </h2>
              <div className="overflow-hidden relative h-[122px]">
                <Image
                  className="group-hover:scale-105 object-cover aspect-[16/9] transition"
                  fill
                  placeholder="blur"
                  blurDataURL={data.featuredCard.placeholder}
                  sizes="100vw"
                  src={data.featuredCard.image}
                  alt={data.featuredCard.label}
                />
              </div>

              {/* Buttons in 2 rows */}
              <div className="flex flex-wrap gap-2 p-4 bg-[#E6EEF7]">
                {data.featuredCard.tabs.map((button: any, index: React.Key | null | undefined) => (
                  <AppButton
                    key={index}
                    label={button.key}
                    link={button.value}
                    intent={"secondary"}
                    className="px-[16px] py-[6px] bg-[#FFFFFF] text-center border rounded-[5px] border-[#E6E6E6] gap-0 font-sairaSemiCondensed font-[500] text-[14px] h-[36px]"
                    onClick={() => {
                      closeMenu();
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Secondary Feature Card Block */}
          {data.secondaryFeaturedCard && (
            <CustomLink
              href={data.secondaryFeaturedCard.ctaLink}
              className="group overflow-hidden bg-white cursor-pointer w-[428px]"
            >
              <div className="overflow-hidden relative h-[122px]">
                <Image
                  className="group-hover:scale-105 object-cover aspect-[16/9] transition"
                  fill
                  placeholder="blur"
                  blurDataURL={data.secondaryFeaturedCard.placeholder}
                  sizes="100vw"
                  src={data.secondaryFeaturedCard.image}
                  alt={data.secondaryFeaturedCard.label}
                />
              </div>
              <div className="bg-[#F2F2F2] p-4">
                <div className="mb-2 text-[14px] font-sairaSemiCondensed font-[600] text-[#000000]">
                  {data.secondaryFeaturedCard.ctaLabel}
                </div>
                <div className="text-[14px] font-saira font-[400] text-[#333333]">
                  {data.secondaryFeaturedCard.subheading}
                </div>
              </div>
            </CustomLink>
          )}
        </div>
      )}
    </div>
  );
};

export default NavPanelNoMenu;
