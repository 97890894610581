/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLayoutEffect } from "react";
import { usePathname } from "next/navigation";

import ContentLayout from "../layouts/ContentLayout";
import { AppButton } from "../buttons/AppButton";
import ProfileButton from "./ProfileButton";
import SearchButton from "./SearchButton";
import MenuButton from "./MenuButton";
import { WSLogoLarge, WSLogoSmall } from "@/assets/Icons";
import CustomLink from "../other/CustomLink";
import CtaVersion from "../buttons/CtaVersion";
import MobileProfileButton from "./MobileProfileButton";
import dynamic from "next/dynamic";

const TelNumberNavbar = dynamic(() => import("./TelNumberNavbar"), { ssr: false });

interface ITopBar {
  isLargeScreen: boolean;
  mobileMenuOpen: boolean;
  handleMobileMenu: () => void;
  handleSearchMenu: () => void;
  setPanelOpen: (arg: null) => void;
  dict: { [key: string]: { [key: string]: string } };
  ctaTopBarButtons: any;
  portalLoginCtaLink: string;
  contactNumber?: any;
}

const topBarMock = {
  profile: {
    email: null,
    avatarIconUrl: "/icons/user-icon-blue.svg",
    linkToPlatform: process.env.NEXT_PUBLIC_PORTAL_PUBLIC_URL ?? "https://dev-portal.awsdev.willscot.com/",
  },
};

const TopBar = ({
  isLargeScreen,
  mobileMenuOpen,
  handleMobileMenu,
  handleSearchMenu,
  setPanelOpen,
  dict,
  ctaTopBarButtons,
  portalLoginCtaLink,
  contactNumber,
}: ITopBar) => {
  const pathname = usePathname();

  useLayoutEffect(() => {
    const main = document.querySelector("main") as HTMLElement;
    const header = document.querySelector("#navHeader") as HTMLElement;
    const isESG = pathname.includes("/about-us/sustainability-impact");
    const isHomePage = pathname === "/en" || pathname === "/fr";

    if (!main || !header) return;

    if (!isLargeScreen) {
      if (isESG) {
        main.style.marginTop = "160px";
        header.style.height = "65px";
        return;
      }

      isHomePage ? (main.style.marginTop = "65px") : (main.style.marginTop = "105px");

      header.style.height = "65px";
      return;
    }

    header.style.height = "144px";

    isESG
      ? (main.style.marginTop = "243px")
      : isHomePage
      ? (main.style.marginTop = "145px")
      : (main.style.marginTop = "186px");
  }, [isLargeScreen, pathname]);

  return (
    <ContentLayout
      innerClass="py-[12px] sm:py-[12px] lg:py-4"
      onClick={() => setPanelOpen(null)}
      className="border-b-[1px] border-black-20 z-10 relative bg-white"
    >
      <div className="flex items-center justify-between w-full h-10 lg:h-[56px]">
        <a
          href="#main-content"
          className="focus-visible:translate-y-0 bg-navy-blue text-14 font-saira outline-navy-blue outline-offset-1 top-1 left-1 absolute z-50 px-4 py-2 text-white transition transform -translate-y-[100px]"
        >
          {dict.navigation.skipToMain}
        </a>
        <CustomLink href="/" ariaLabel={dict.ariaLabels.buttonLogo}>
          {isLargeScreen ? <WSLogoLarge /> : <WSLogoSmall />}
        </CustomLink>

        <div className="flex gap-3">
          {isLargeScreen && <TelNumberNavbar contactNumber={contactNumber} />}
          {ctaTopBarButtons && ctaTopBarButtons.length && isLargeScreen ? (
            ctaTopBarButtons.map((button: any) => <CtaVersion key={button.id} {...button} />)
          ) : (
            <>
              <AppButton
                intent="secondary"
                label={dict.navigation.request_service}
                ariaLabel={dict.navigation.request_service}
                link="/request-service"
                className="lg:inline-flex hidden"
              />

              <AppButton
                intent="primary"
                label={dict.navigation.request_quote}
                ariaLabel={dict.navigation.request_quote}
                link="/request-quote"
                className="lg:inline-flex hidden"
              />
            </>
          )}

          {isLargeScreen ? (
            <ProfileButton
              {...topBarMock.profile}
              portalLoginCtaLink={portalLoginCtaLink}
              label={dict.navigation.signIn || ""}
            />
          ) : (
            <MobileProfileButton
              {...topBarMock.profile}
              label={dict.navigation.signIn || ""}
              portalLoginCtaLink={portalLoginCtaLink}
              showChevronDownButton={false}
            />
          )}

          {!isLargeScreen && (
            <>
              <div onClick={handleSearchMenu}>
                <SearchButton className="lg:hidden focus-visible:outline-dijon flex rounded outline-none" />
              </div>
              {contactNumber && (
                <TelNumberNavbar contactNumber={contactNumber} showNumber={false} size={25} strokeWidth={1} />
              )}
              <div onClick={handleMobileMenu} className="lg:hidden">
                <MenuButton open={mobileMenuOpen} className="focus-visible:outline-dijon flex rounded outline-none" />
              </div>
            </>
          )}
        </div>
      </div>
    </ContentLayout>
  );
};

export default TopBar;
